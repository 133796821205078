
export default {
  data() {  
    return {
      numImages: 5,
      desktop: [
        require('@/assets/carousel-2024/desktop/1.png'),
        require('@/assets/carousel-2024/desktop/2.png'),
        require('@/assets/carousel-2024/desktop/3.png'),
        require('@/assets/carousel-2024/desktop/4.png'),
        require('@/assets/carousel-2024/desktop/5.png'),
      ],
      mobile: [
        require('@/assets/carousel-2024/mobile/1.png'),
        require('@/assets/carousel-2024/mobile/2.png'),
        require('@/assets/carousel-2024/mobile/3.png'),
        require('@/assets/carousel-2024/mobile/4.png'),
        require('@/assets/carousel-2024/mobile/5.png'),
      ],
    }
  },
}
